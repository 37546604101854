<script>
  import Title from "./components/Title.svelte";
  import MenuButton from "./components/MenuButton.svelte";

  let pruducts = ["PRODUCT1 ", "PRODUCT2 ", "PRODUCT3 ", "PRODUCT4 "];
</script>

<main>
  <div>
    <div class="container">
      <div>
        <Title />
      </div>
      <div display="flex" flex-direction="row">
        {#each pruducts as pruduct}
          <p>{pruduct}</p>
        {/each}
      </div>
      <div>
        <MenuButton />
      </div>
    </div>
  </div>
</main>

<style>
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 14px;
  }
</style>
